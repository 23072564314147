body {
  // Color white
  --color-white: #ffffff;

  // Color natural
  --color-natural-180: #141418;
  --color-natural-160: #282831;
  --color-natural-140: #3b3c49;
  --color-natural-120: #4f5062;
  --color-natural-100: #63647a;
  --color-natural-80: #828395;
  --color-natural-60: #a1a2af;
  --color-natural-40: #c1c1ca;
  --color-natural-20: #e0e0e4;
  --color-natural-10: #eff0f2;
  --color-natural-5: #f7f7f8;

  // Color primary
  --color-primary-180: #0e0e1e;
  --color-primary-160: #1c1c3d;
  --color-primary-140: #292b5b;
  --color-primary-120: #37397a;
  --color-primary-100: #454798;
  --color-primary-80: #6a6cad;
  --color-primary-60: #8f91c1;
  --color-primary-40: #b5b5d6;
  --color-primary-20: #dadaea;
  --color-primary-10: #ecedf5;
  --color-primary-5: #f6f6fa;

  // Color secondary
  --color-secondary-180: #082426;
  --color-secondary-160: #0f484c;
  --color-secondary-140: #176d72;
  --color-secondary-120: #1e9198;
  --color-secondary-100: #26b5be;
  --color-secondary-80: #51c4cb;
  --color-secondary-60: #7dd3d8;
  --color-secondary-40: #a8e1e5;
  --color-secondary-20: #d4f0f2;
  --color-secondary-10: #e9f8f9;
  --color-secondary-5: #f4fbfc;

  // Color success
  --color-success-160: #0c341e;
  --color-success-140: #124e2e;
  --color-success-120: #18683d;
  --color-success-100: #1e824c;
  --color-success-80: #4b9b70;
  --color-success-60: #78b494;
  --color-success-40: #a5cdb7;
  --color-success-20: #d2e6db;
  --color-success-10: #e9f3ed;
  --color-success-5: #f4f9f6;

  // Color warning
  --color-warning-160: #5d4a21;
  --color-warning-140: #8b7031;
  --color-warning-120: #ba9542;
  --color-warning-100: #e8ba52;
  --color-warning-80: #edc875;
  --color-warning-60: #f1d697;
  --color-warning-40: #f6e3ba;
  --color-warning-20: #faf1dc;
  --color-warning-10: #fdf8ee;
  --color-warning-5: #fefcf6;

  // Color danger
  --color-danger-160: #5b1206;
  --color-danger-140: #881a0a;
  --color-danger-120: #b6230d;
  --color-danger-100: #e22c10;
  --color-danger-80: #e95640;
  --color-danger-60: #ee8070;
  --color-danger-40: #f4ab9f;
  --color-danger-20: #f9d5cf;
  --color-danger-10: #fceae7;
  --color-danger-5: #fef4f3;

  // Other colors
  --color-linkedin: #0a66c2;
  --color-x: #000000;

  // Font sizes
  --font-size-xxs: 12px;
  --font-size-xs: 13px;
  --font-size-s: 14px;
  --font-size-normal: 16px;
  --font-size-l: 18px;
  --font-size-xl: 20px;
  --font-size-xxl: 24px;
  --font-size-h: 30px;
  --font-size-xh: 40px;
  --font-size-xxh: 60px;

  // Font line heights
  --font-line-height-s: 24px;
  --font-line-height-normal: 26px;

  // Font weights
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;
  --font-weight-extrabold: 900;

  // Spacing base
  --spacing-base: 5px;
  --spacing-2x: 10px;
  --spacing-3x: 15px;
  --spacing-4x: 20px;
  --spacing-5x: 25px;
  --spacing-6x: 30px;
  --spacing-7x: 35px;
  --spacing-8x: 40px;
  --spacing-9x: 45px;
  --spacing-10x: 50px;
  --spacing-12x: 60px;
  --spacing-16x: 80px;
  --spacing-20x: 100px;

  // Theme variables
  --acc-text-color: var(--color-natural-180);
  --acc-link-color: var(--color-primary-100);
  --acc-outline: 4px solid var(--color-secondary-180);
  --acc-border-radius: 4px;
}
