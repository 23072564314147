@use 'responsive';

$contentMdWidth: responsive.$breakpoint-md - 32px;
$contentLgWidth: responsive.$breakpoint-lg - 32px;
$contentMlWidth: 1300px;

$detailPanelWidth: 260px;
$detailPanelLgWidth: 300px;
$detailPanelMarginMd: calc(1.8 * #{var(--theme-medium-margin)});
$detailPanelMarginLg: calc(1.5 * #{var(--theme-wide-margin)});
$detailPanelMarginMl: calc(1.7 * #{var(--theme-wide-margin)});
