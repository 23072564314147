@charset "UTF-8";

@font-face {
  font-family: 'acc-icon-font';
  src: url('/assets/fonts/acc-icon-font/acc-icon-font.woff') format('woff'),
    url('/assets/fonts/acc-icon-font/acc-icon-font.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: 'acc-icon-font' !important;
  font-weight: normal !important;
  font-style: normal !important;
  font-variant: normal !important;
  line-height: 1;
  text-transform: none !important;
  content: attr(data-icon);
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^='acc-icon-']:before,
[class*=' acc-icon-']:before {
  font-family: 'acc-icon-font' !important;
  font-weight: normal !important;
  font-style: normal !important;
  font-variant: normal !important;
  line-height: 1;
  text-transform: none !important;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.acc-icon-arrow-up:before {
  content: '\61';
}

.acc-icon-desktop:before {
  content: '\66';
}

.acc-icon-mobile:before {
  content: '\67';
}

.acc-icon-undo:before {
  content: '\68';
}

.acc-icon-repeat:before {
  content: '\69';
}

.acc-icon-upload:before {
  content: '\6c';
}

.acc-icon-floppy-o:before {
  content: '\6d';
}

.acc-icon-action-add:before {
  content: '\6f';
}

.acc-icon-action-copy:before {
  content: '\70';
}

.acc-icon-badge-design:before {
  content: '\71';
}

.acc-icon-branding:before {
  content: '\72';
}

.acc-icon-file-image:before {
  content: '\73';
}

.acc-icon-file-pdf:before {
  content: '\74';
}

.acc-icon-file-spreadsheet:before {
  content: '\75';
}

.acc-icon-settings:before {
  content: '\78';
}

.acc-icon-time:before {
  content: '\79';
}

.acc-icon-user:before {
  content: '\7a';
}

.acc-icon-user-admin:before {
  content: '\41';
}

.acc-icon-user-group:before {
  content: '\42';
}

.acc-icon-action-delete:before {
  content: '\43';
}

.acc-icon-action-delete-hard:before {
  content: '\44';
}

.acc-icon-action-edit:before {
  content: '\45';
}

.acc-icon-cert-group:before {
  content: '\48';
}

.acc-icon-action-publish:before {
  content: '\4a';
}

.acc-icon-certificate:before {
  content: '\4b';
}

.acc-icon-certificate-design:before {
  content: '\4c';
}

.acc-icon-action-search:before {
  content: '\4e';
}

.acc-icon-action-select:before {
  content: '\4f';
}

.acc-icon-all-groups:before {
  content: '\50';
}

.acc-icon-badge:before {
  content: '\51';
}

.acc-icon-email-template:before {
  content: '\52';
}

.acc-icon-email:before {
  content: '\53';
}

.acc-icon-credential-list:before {
  content: '\54';
}

.acc-icon-credential:before {
  content: '\55';
}

.acc-icon-dismiss:before {
  content: '\62';
}

.acc-icon-facebook:before {
  content: '\6a';
}

.acc-icon-linkedin:before {
  content: '\76';
}

.acc-icon-twitter-alt:before {
  content: '\47';
}

.acc-icon-google-plus:before {
  content: '\46';
}

.acc-icon-expire:before {
  content: '\56';
}

.acc-icon-linkedin-alt:before {
  content: '\57';
}

.acc-icon-lock:before {
  content: '\58';
}

.acc-icon-unlock:before {
  content: '\59';
}

.acc-icon-share-square-o:before {
  content: '\5a';
}

.acc-icon-sort-1:before {
  content: '\6b';
}

.acc-icon-signal:before {
  content: '\63';
}

.acc-icon-code:before {
  content: '\65';
}

.acc-icon-tasks:before {
  content: '\31';
}

.acc-icon-university:before {
  content: '\32';
}

.acc-icon-graduation-cap:before {
  content: '\33';
}

.acc-icon-commenting:before {
  content: '\34';
}

.acc-icon-note:before {
  content: '\35';
}

.acc-icon-wrench:before {
  content: '\36';
}

.acc-icon-bars:before {
  content: '\37';
}

.acc-icon-exclamation-triangle:before {
  content: '\39';
}

.acc-icon-eye:before {
  content: '\21';
}

.acc-icon-eye-slash:before {
  content: '\22';
}

.acc-icon-phone:before {
  content: '\23';
}

.acc-icon-refresh:before {
  content: '\25';
}

.acc-icon-italic:before {
  content: '\26';
}

.acc-icon-underline:before {
  content: '\27';
}

.acc-icon-print:before {
  content: '\28';
}

.acc-icon-money:before {
  content: '\29';
}

.acc-icon-file-blank:before {
  content: '\2a';
}

.acc-icon-area-chart:before {
  content: '\2b';
}

.acc-icon-calendar:before {
  content: '\2c';
}

.acc-icon-user-1:before {
  content: '\2d';
}

.acc-icon-key:before {
  content: '\2e';
}

.acc-icon-play-circle:before {
  content: '\3a';
}

.acc-icon-multiselect-1:before {
  content: '\3b';
}

.acc-icon-multiselect-2:before {
  content: '\3c';
}

.acc-icon-multiselect-3:before {
  content: '\3d';
}

.acc-icon-multiselect-4:before {
  content: '\3e';
}

.acc-icon-multiselect-5:before {
  content: '\3f';
}

.acc-icon-anchor:before {
  content: '\40';
}

.acc-icon-flask:before {
  content: '\5b';
}

.acc-icon-list-ol:before {
  content: '\5d';
}

.acc-icon-list-ul:before {
  content: '\5e';
}

.acc-icon-angle-double-right:before {
  content: '\5f';
}

.acc-icon-bold:before {
  content: '\60';
}

.acc-icon-align-center:before {
  content: '\7b';
}

.acc-icon-align-justify:before {
  content: '\7c';
}

.acc-icon-align-left:before {
  content: '\7d';
}

.acc-icon-align-right:before {
  content: '\7e';
}

.acc-icon-indent:before {
  content: '\5c';
}

.acc-icon-outdent:before {
  content: '\e000';
}

.acc-icon-strikethrough:before {
  content: '\e001';
}

.acc-icon-link:before {
  content: '\e002';
}

.acc-icon-object-ungroup:before {
  content: '\e003';
}

.acc-icon-spinner:before {
  content: '\e004';
}

.acc-icon-arrows-h:before {
  content: '\e005';
}

.acc-icon-ellipsis-h:before {
  content: '\e006';
}

.acc-icon-filter:before {
  content: '\e007';
}

.acc-icon-table:before {
  content: '\e008';
}

.acc-icon-credit-card:before {
  content: '\e009';
}

.acc-icon-moodle:before {
  content: '\e00a';
}

.acc-icon-mozilla-backpack:before {
  content: '\e00b';
}

.acc-icon-zapier:before {
  content: '\e00c';
}

.acc-icon-info-circle:before {
  content: '\e00d';
}

.acc-icon-font:before {
  content: '\e00e';
}

.acc-icon-trophy:before {
  content: '\e00f';
}

.acc-icon-canvas:before {
  content: '\e010';
}

.acc-icon-tags:before {
  content: '\e012';
}

.acc-icon-merge:before {
  content: '\e011';
}

.acc-icon-blockchain:before {
  content: '\e013';
}

.acc-icon-sort-asc:before {
  content: '\e015';
}

.acc-icon-sort-desc:before {
  content: '\e016';
}

.acc-icon-caret-centered:before {
  content: '\30';
}

.acc-icon-search-plus:before {
  content: '\e014';
}

.acc-icon-search-minus:before {
  content: '\e017';
}

.acc-icon-map-marker:before {
  content: '\e018';
}

.acc-icon-globe:before {
  content: '\e019';
}

.acc-icon-barcode:before {
  content: '\e01a';
}

.acc-icon-send-example:before {
  content: '\e01b';
}

.acc-icon-add-credentials:before {
  content: '\e01c';
}

.acc-icon-designs:before {
  content: '\e01d';
}

.acc-icon-groups:before {
  content: '\e01e';
}

.acc-icon-orientation:before {
  content: '\e01f';
}

.acc-icon-circle-thin:before {
  content: '\e020';
}

.acc-icon-bridge:before {
  content: '\e021';
}

.acc-icon-wordpress:before {
  content: '\e022';
}

.acc-icon-circle:before {
  content: '\e023';
}

.acc-icon-brightspace:before {
  content: '\e025';
}

.acc-icon-arrows:before {
  content: '\e026';
}

.acc-icon-unverified:before {
  content: '\e028';
}

.acc-icon-verified:before {
  content: '\49';
}

.acc-icon-action-remove:before {
  content: '\4d';
}

.acc-icon-question-circle-icon:before {
  content: '\38';
}

.acc-icon-tick-circle:before {
  content: '\2f';
}

.acc-icon-external-link:before {
  content: '\e024';
}

.acc-icon-briefcase:before {
  content: '\6e';
}

.acc-icon-caret-down:before {
  content: '\77';
}

.acc-icon-caret-left:before {
  content: '\e027';
}

.acc-icon-caret-right:before {
  content: '\e029';
}

.acc-icon-caret-up:before {
  content: '\e02a';
}

.acc-icon-star-two:before {
  content: '\e02c';
}

.acc-icon-bookmark-o:before {
  content: '\e02d';
}

.acc-icon-photo:before {
  content: '\e02b';
}

.acc-icon-th-large:before {
  content: '\e02e';
}

.acc-icon-eyedropper:before {
  content: '\e02f';
}

.acc-icon-opacity:before {
  content: '\e030';
}

.acc-icon-text:before {
  content: '\e031';
}

.acc-icon-qrcode:before {
  content: '\e032';
}

.acc-icon-list:before {
  content: '\24';
}

.acc-icon-shrink-to-height:before {
  content: '\e033';
}

.acc-icon-shrink-to-width:before {
  content: '\e034';
}

.acc-icon-vertical-align-bottom:before {
  content: '\e035';
}

.acc-icon-vertical-align-middle:before {
  content: '\e036';
}

.acc-icon-vertical-align-top:before {
  content: '\e037';
}

.acc-icon-annotate:before {
  content: '\e038';
}

.acc-icon-share:before {
  content: '\e039';
}

.acc-icon-youtube:before {
  content: '\64';
}

.acc-icon-rss:before {
  content: '\e03a';
}

.acc-icon-pathway-cluster:before {
  content: '\e03b';
}

.acc-icon-pathway-stacked:before {
  content: '\e03c';
}

.acc-icon-instagram:before {
  content: '\e03d';
}
