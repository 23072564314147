@use 'responsive';
@use 'variables';

body {
  // This is the Angular 'root' element level (e.g. 'rp-root'), which is different for different properties
  #app-root > mat-sidenav-container {
    &.no-footer {
      > mat-sidenav-content {
        > main {
          flex: 0;
        }
      }
    }

    > mat-sidenav-content {
      display: flex;
      flex-direction: column;
      height: 100vh;

      @media print {
        height: auto;
        min-height: auto;
      }

      > header {
        position: relative;
        z-index: 100;
        height: var(--theme-header-height);
        min-height: var(--theme-header-height);
        color: var(--theme-header-text-color);
        background-color: var(--theme-header-color);
        box-shadow: var(--theme-header-box-shadow);

        @media print {
          display: none;
        }
      }

      > main {
        position: relative;
        flex: 1 0 auto;
        padding-bottom: var(--theme-wide-margin);

        @include responsive.media-gt(xs) {
          padding-bottom: calc(var(--theme-wide-margin) * 3);
        }
      }

      > footer {
        position: relative;
        flex-shrink: 0;
        color: var(--theme-footer-text-color);
        background-color: var(--theme-footer-color);

        @media print {
          display: none;
        }
      }
    }
  }

  // Headings
  .mat-h1,
  .mat-h2,
  .mat-h3,
  .mat-h4 {
    &.no-margin {
      margin: 0;
    }
  }

  &.mat-typography h4,
  .mat-h4,
  .mat-subheading-1 {
    margin: 0 0 var(--theme-slight-margin);
    text-transform: var(--theme-subheading-case);

    &.plain {
      text-transform: initial;
    }
  }

  a {
    color: var(--theme-link-color);

    &.link-with-icon {
      display: flex;
      align-items: center;

      &:hover {
        text-decoration: none;

        > span {
          text-decoration: underline;
        }
      }
    }
  }

  .btn-a-tag {
    font-size: 16px;
    font-weight: 500;
    color: var(--theme-link-color);
    background: none;

    &:hover {
      text-decoration: underline;
    }
  }

  // Form margins
  form {
    .form-row:not(:last-child) {
      margin-bottom: var(--theme-slight-margin);
    }
  }

  // Main layout classes
  .column-structure {
    display: flex;
    flex-direction: column;

    @include responsive.media-gt(md) {
      flex-direction: row;
    }

    > * {
      &.action-panel {
        margin-bottom: var(--theme-wide-margin);

        @include responsive.media-gt(md) {
          flex-basis: 304px;
          margin-bottom: 0;
        }
      }

      &.main-panel {
        margin-bottom: var(--theme-wide-margin);

        @include responsive.media-gt(md) {
          flex: 1;
          margin-bottom: 0;
        }
      }

      &.detail-panel {
        margin-bottom: var(--theme-wide-margin);

        @include responsive.media-gt(md) {
          flex-basis: variables.$detailPanelWidth;
          margin-bottom: 0;
        }

        @include responsive.media-gt(lg) {
          flex-basis: variables.$detailPanelLgWidth;
        }
      }
    }
  }

  .content {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 0 var(--theme-slight-margin);

    @include responsive.media-gt(xxs) {
      padding: 0 var(--theme-default-margin);
    }

    @include responsive.media-gt(md) {
      width: variables.$contentMdWidth;
    }

    @include responsive.media-gt(lg) {
      width: variables.$contentLgWidth;
    }

    @include responsive.media-gt(ml) {
      width: variables.$contentMlWidth;
    }

    &.with-vertical-padding {
      padding: calc(var(--theme-default-margin) * 2) var(--theme-slight-margin) 0;

      @include responsive.media-gt(xxs) {
        padding: calc(var(--theme-default-margin) * 2) var(--theme-default-margin) 0;
      }

      @include responsive.media-gt(sm) {
        padding: calc(var(--theme-default-margin) * 3) var(--theme-default-margin) 0;
      }
    }
  }

  .maximal-content {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 0 var(--theme-slight-margin);

    @include responsive.media-gt(xxs) {
      padding: 0 var(--theme-default-margin);
    }

    @include responsive.media-gt(sm) {
      padding: 0 var(--theme-wide-margin);
    }

    &.with-vertical-padding {
      padding: calc(var(--theme-default-margin) * 2) var(--theme-slight-margin) 0;

      @include responsive.media-gt(xxs) {
        padding: calc(var(--theme-default-margin) * 2) var(--theme-default-margin) 0;
      }

      @include responsive.media-gt(sm) {
        padding: calc(var(--theme-default-margin) * 3) var(--theme-wide-margin) 0;
      }
    }
  }

  // Row with equal sized cells
  .row {
    display: flex;
    flex-wrap: wrap;

    // Modifiers
    &.with-margins {
      @include responsive.media-gt(xs) {
        margin-bottom: var(--theme-default-margin);
      }
    }

    &.align-top {
      align-items: flex-start;
    }

    &.align-middle {
      align-items: center;
    }

    &.align-bottom {
      align-items: flex-end;
    }

    &.fill-vertical-space {
      height: 100%;
    }

    // Cells
    > * {
      // border: 1px solid greenyellow;
      flex: 1 0 1px;
      min-width: 1px;

      // Modifiers
      &.min-size {
        flex: 0;
      }

      &.cling {
        flex: 0 1 auto;
      }

      &.align-middle {
        align-self: center;
      }

      &.align-bottom {
        align-self: flex-end;
      }

      &.align-stretch {
        align-self: stretch;
      }

      &.fill-vertical-space {
        height: 100%;
      }

      // Cells shouldn't be rows themselves (nest them WITHIN the cell)
      &.row:after {
        padding: 3px 6px;
        color: white;
        background-color: black;
        content: 'CSS Error: ';
      }
    }
  }

  .main-header {
    display: flex;
    align-items: center;
    min-height: var(--theme-main-header-height);
    color: var(--theme-main-header-text-color);
    background-color: var(--theme-main-header-color);
    border-bottom: var(--theme-main-header-border);

    .link-with-icon {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: var(--theme-default-margin);
      text-decoration: none;

      mat-icon {
        margin-right: calc(var(--theme-slight-margin) / 2);
      }
    }

    .mat-h1 {
      margin: 0;
    }
  }

  .tile {
    display: block;
    width: 288px;
    margin-bottom: var(--theme-default-margin);
    color: var(--theme-tile-text-color);
    background-color: var(--theme-tile-color);
    border: var(--theme-tile-border);
    border-radius: var(--theme-tile-border-radius);
    box-shadow: var(--theme-tile-box-shadow);
    transition: 0.1s all ease-in-out;

    &:not(.no-hover) {
      &:hover:not(.ghost) {
        box-shadow: var(--theme-tile-hover-box-shadow);
        transform: translateY(-5px);
      }
    }

    &.ghost {
      background-color: transparent;
      border: none;
    }
  }

  .header-tile {
    margin-bottom: var(--theme-wide-margin);
    padding: var(--theme-default-margin);
    color: var(--theme-header-tile-text-color);
    background-color: var(--theme-header-tile-color);
    border-radius: var(--theme-border-radius);
    box-shadow: var(--theme-header-tile-box-shadow);

    @include responsive.media-gt(xs) {
      margin-right: 0;
      padding: var(--theme-wide-margin);
    }
  }

  // Responsive rows
  @each $key, $width in responsive.$breakpoints-map {
    .row-#{$key} {
      @extend .row;
      // Remove flexing when smaller than the breakpoint
      @include responsive.media-lt($key) {
        display: block !important;
        > * {
          width: initial !important;
          min-width: initial !important;
          padding: 0 !important;

          &:not(:last-child) {
            margin-bottom: var(--theme-default-margin);
          }
        }
      }
    }
  }

  // Content hiding classes
  @each $key, $width in responsive.$breakpoints-map {
    // Less than
    .hidden-lt-#{$key} {
      @include responsive.media-lt($key) {
        display: none !important;
      }
    }
    // Greater than
    .hidden-gt-#{$key} {
      @include responsive.media-gt($key) {
        display: none !important;
      }
    }
  }

  &[dir='ltr'] {
    form {
      .form-row {
        > *:not(:last-child) {
          margin-right: var(--theme-default-margin);
        }
      }
    }

    .column-structure {
      > * {
        &.action-panel {
          margin-right: 0;
          margin-left: 0;

          @include responsive.media-gt(md) {
            margin-right: var(--theme-medium-margin);
          }
        }

        &.detail-panel {
          margin-right: 0;

          @include responsive.media-gt(md) {
            margin-left: calc(#{variables.$detailPanelMarginMd});
          }

          @include responsive.media-gt(lg) {
            margin-left: calc(#{variables.$detailPanelMarginLg});
          }

          @include responsive.media-gt(ml) {
            margin-left: calc(#{variables.$detailPanelMarginMl});
          }
        }
      }
    }

    .row {
      &.with-margins {
        margin-right: -(var(--theme-default-margin));

        > * {
          margin-right: var(--theme-default-margin);
        }
      }
    }

    .tile {
      margin-right: var(--theme-default-margin);

      @include responsive.media-lt(xs) {
        margin-right: 0;
      }
    }

    .trailing-margin {
      margin-right: var(--theme-trailing-margin);
    }

    .trailing-margin-narrow {
      margin-right: calc(var(--theme-trailing-margin) / 2);
    }

    .trailing-margin-wide {
      margin-right: calc(var(--theme-trailing-margin) * 2);
    }
  }

  &[dir='rtl'] {
    form {
      .form-row {
        > *:not(:last-child) {
          margin-left: var(--theme-default-margin);
        }
      }
    }

    .column-structure {
      > * {
        &.action-panel {
          margin-right: 0;
          margin-left: 0;

          @include responsive.media-gt(md) {
            margin-left: var(--theme-medium-margin);
          }
        }

        &.detail-panel {
          margin-left: 0;

          @include responsive.media-gt(md) {
            margin-right: calc(#{variables.$detailPanelMarginMd});
          }

          @include responsive.media-gt(lg) {
            margin-right: calc(#{variables.$detailPanelMarginLg});
          }

          @include responsive.media-gt(ml) {
            margin-right: calc(#{variables.$detailPanelMarginMl});
          }
        }
      }
    }

    .row {
      &.with-margins {
        margin-left: -(var(--theme-default-margin));

        > * {
          margin-left: var(--theme-default-margin);
        }
      }
    }

    .tile {
      margin-left: var(--theme-default-margin);

      @include responsive.media-lt(xs) {
        margin-left: 0;
      }
    }

    .trailing-margin {
      margin-left: var(--theme-trailing-margin);
    }

    .trailing-margin-narrow {
      margin-left: calc(var(--theme-trailing-margin) / 2);
    }

    .trailing-margin-wide {
      margin-left: calc(var(--theme-trailing-margin) * 2);
    }
  }
}

button,
a {
  &.mat-flat-button.mat-primary,
  &.mat-raised-button.mat-primary,
  &.mat-fab.mat-primary,
  &.mat-mini-fab.mat-primary {
    color: var(--theme-primary-button-text-color);

    &:hover:not(.mat-button-disabled) {
      color: var(--theme-primary-button-hover-color);
      background-color: var(--theme-primary-button-background-hover-color);
    }
  }
}

button.link-button {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #63647a;
  background: transparent;
  border: none;

  &:hover {
    color: var(--primary-color);
  }

  .mat-ripple,
  .mat-button-focus-overlay {
    display: none;
  }
}

.widget {
  margin-right: calc(-1 * var(--theme-slight-margin));
  margin-bottom: var(--theme-medium-margin);
  margin-left: calc(-1 * var(--theme-slight-margin));
  padding: var(--theme-medium-margin);
  color: var(--theme-tile-text-color);
  background-color: var(--theme-tile-color);
  border-top: 1px solid var(--theme-border-color);
  border-bottom: 1px solid var(--theme-border-color);

  @include responsive.media-gt(xxs) {
    margin-right: calc(-1 * var(--theme-default-margin));
    margin-bottom: var(--theme-wide-margin);
    margin-left: calc(-1 * var(--theme-default-margin));
  }

  @include responsive.media-gt(xs) {
    margin-right: 0;
    margin-left: 0;
    border: 1px solid var(--theme-border-color);
    border-radius: var(--theme-tile-border-radius);
  }

  h2 {
    font-size: 19px;
    font-weight: bold;
  }
}

// Mobile popup menu
.mobile-popup-menu {
  width: 100%;

  .pop-up-content {
    div {
      ul {
        li {
          button,
          a,
          mat-icon {
            color: #63647a;

            &:hover {
              text-decoration: none;
            }
          }
        }

        li:not(:last-of-type) {
          button,
          a {
            border-bottom: 1px solid #e0e0e4;
          }
        }
      }
    }
  }
}
