.main-panel-form {
  .text {
    margin-bottom: var(--theme-wide-margin);

    h1 {
      margin-bottom: var(--theme-default-margin);
    }
  }

  .mat-flat-button {
    width: 100%;
    height: 100%;
  }
}

// Hide Google reCaptcha v3 badge
// Source: https://developers.google.com/recaptcha/docs/faq
.grecaptcha-badge {
  visibility: hidden;
}
