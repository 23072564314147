@use 'responsive';

.acc-dialog {
  @include responsive.media-lt(xs) {
    max-width: 100% !important;
  }
}

.cdk-overlay-pane {
  max-height: 100vh;
}

.mat-dialog-actions {
  margin-bottom: -16px !important;
}
