@use 'responsive';

.mat-display-1 {
  @include responsive.media-lt(md) {
    font-size: 20px;
    line-height: 26px;
  }
}

.mat-display-2 {
  @include responsive.media-lt(md) {
    font-size: 24px;
    line-height: 32px;
  }
}

.mat-display-3 {
  @include responsive.media-lt(md) {
    font-size: 30px;
    line-height: 38px;
  }
}

.mat-display-4 {
  @include responsive.media-lt(md) {
    font-size: 38px;
    line-height: 46px;
  }
}
