.ghost {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    display: inline-block;
  }
}

@mixin ghost($width: 0, $height: 0, $top: 0, $bottom: 0, $isAlternate: false) {
  @if ($width > 0) {
    width: $width;
  }

  @if ($height > 0) {
    height: $height;
  }

  @if ($top > 0) {
    position: relative;
    top: $top;
  }

  @if ($bottom > 0) {
    position: relative;
    bottom: $bottom;
  }

  @if ($isAlternate) {
    background-color: var(--theme-ghost-alternate-color);
  } @else {
    background-color: var(--theme-ghost-color);
  }

  border: none;
  border-radius: var(--theme-border-radius);
  box-shadow: var(--theme-box-shadow);
  pointer-events: none;

  color: transparent;
}

@mixin ghostLine($width: 0, $height: 0, $top: 0, $bottom: 0, $isAlternate: false) {
  @include ghost($width, $height, $top, $bottom, $isAlternate);
  white-space: nowrap;

  &:after {
    content: 'G';
  }
}

// ---------------------------------------------------------------
// Commented if later we want to add an animation to our ghosts
// ---------------------------------------------------------------

//$animation-duration: 1000ms;
//$gradient-offset: 52 + 16;

//@keyframes ghost-lines {
//  0% {
//    background-position: -100px;
//  }
//  40% {
//    background-position: 40vw;
//  }
//  100% {
//    background-position: 60vw;
//  }
//}
//
//@keyframes ghost-avatar {
//  0% {
//    background-position: -100px + $gradient-offset;
//  }
//  40%, 100% {
//    background-position: 140px + $gradient-offset;
//  }
//}

//@mixin animate-ghost-avatar {
//  @include ghostLine;
//  animation: ghost-avatar $animation-duration infinite linear;
//}
//
//@mixin animate-ghost-line($width: 0, $height: 0) {
//  @include ghostLine($width, $height);
//  animation: ghost-lines $animation-duration infinite linear;
//}
