.mat-button,
.mat-raised-button,
.mat-stroked-button,
.mat-flat-button {
  border-radius: var(--theme-button-border-radius) !important;
  box-shadow: var(--theme-box-shadow) !important;

  &:hover {
    text-decoration: none;
  }

  &.mat-button-small {
    font-size: 14px;
    line-height: 24px;
  }

  &.mat-button-large {
    padding: 12px var(--theme-default-margin);
    font-size: 24px;
    font-weight: 300;
    letter-spacing: 1.2px;
  }

  &.cdk-keyboard-focused .mat-button-focus-overlay {
    display: none;
  }
}
