body {
  // Focus indicator
  *:focus {
    outline: 4px solid var(--theme-outline-color);
    outline-offset: 0;
  }

  .outline-alt {
    &:focus {
      outline-color: var(--theme-outline-alt-color);
    }

    &.cdk-keyboard-focused {
      &:before {
        border-color: var(--theme-outline-alt-color);
      }
    }
  }

  // The material dialog component focus the .mat-dialog-container for 1 sec
  // after it moves to the first focusable element
  // We set outline none to avoid showing a 1 sec outline in the dialog
  .mat-dialog-container:focus {
    outline: none;
  }

  // TODO: Refactor in a way that this file would only contain a mixin and apply that mixin in each specific most appropriate scss file.
  // All these elements need position relative in order for the :before to work properly
  .btn-header,
  .mat-button,
  .mat-button-base,
  .mat-button-toggle-button,
  .mat-menu-item,
  .mat-option,
  .mat-tab-link,
  .mat-tab-label,
  button.tile,
  a.chart-wrapper,
  a.collection-tile,
  a[tilebody],
  [tilebody] > a {
    position: relative;
  }

  .btn-header,
  .mat-button,
  .mat-button-base,
  .mat-button-toggle-button,
  .mat-menu-item,
  .mat-option,
  .mat-tab-link,
  .mat-tab-label,
  .mat-tab-body,
  button.tile,
  a.chart-wrapper,
  a.collection-tile,
  a[tilebody],
  [tilebody] > a,
  .collection-wrapper,
  .featured-group {
    outline: none;

    &:focus,
    &.cdk-keyboard-focused,
    &.mat-active.keyboard-focused {
      &:before {
        position: absolute;
        z-index: 1000;
        display: block;
        margin: 0;
        border: 4px solid var(--theme-outline-color);
        content: '';
      }
    }
  }

  // Prevent tab cursor overflowing button bounding box
  .btn-header,
  .tile-actions > div > button {
    &:focus,
    &.cdk-keyboard-focused,
    &.mat-active.keyboard-focused {
      &:before {
        margin: 4px;
      }
    }
  }

  .btn-header,
  .mat-button-toggle-button,
  .mat-menu-item,
  .mat-option,
  .mat-tab-link,
  .mat-tab-label,
  .mat-tab-body,
  button.tile,
  a.chart-wrapper,
  a.collection-tile,
  a[tilebody],
  [tilebody] > a,
  .collection-wrapper,
  .featured-group {
    &:focus,
    &.cdk-keyboard-focused,
    &.mat-active.keyboard-focused {
      &:before {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        margin: 0;
      }
    }
  }

  .mat-button,
  .mat-button-base {
    &.no-outline-margin {
      &:focus:before {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        margin: 0;
      }
    }

    &:focus:before {
      top: -4px;
      right: -4px;
      bottom: -4px;
      left: -4px;
      display: block;
      margin: 0;
    }
  }

  accredible-tile,
  rp-collection-tile {
    a:focus {
      outline-offset: 0 !important;
    }
  }

  // Used to improve the contrast of the persistent-ripple when hovering toggle, radio and checkbox inputs
  .mat-slide-toggle-bar:hover,
  .mat-radio-container:hover,
  .mat-checkbox-inner-container:hover {
    .mat-slide-toggle-persistent-ripple,
    .mat-radio-persistent-ripple,
    .mat-checkbox-persistent-ripple {
      opacity: 0.15;
      transition: opacity 0.2s ease;
    }
  }

  // Used to improve the contrast of the persistent-ripple when keyboard focusing toggle, radio and checkbox inputs
  .mat-slide-toggle:not(.mat-disabled),
  .mat-radio-button:not(.mat-radio-disabled),
  .mat-checkbox {
    &.cdk-keyboard-focused {
      .mat-slide-toggle-persistent-ripple,
      .mat-radio-persistent-ripple,
      .mat-checkbox-persistent-ripple {
        opacity: 0.3;
      }
    }
  }
}

// Used to hide an element from the user, but make it so screen readers still get to it and read its value
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
}

// Used to expand elements with the content fade directive when accessible users tab into the element
// The contentFadeFocusWithin animation is tracked by the content-fade.directive.ts
.el-with-content-fade {
  &:focus-within {
    animation: contentFadeFocusWithin 0s linear;
  }
}

@keyframes contentFadeFocusWithin {
}
