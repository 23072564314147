.mat-divider {
  border-color: var(--theme-border-color);
}

.mat-table {
  thead {
    .mat-header-cell {
      font-size: inherit;
    }
  }

  tbody {
    > tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }
}

.mat-tooltip {
  margin: var(--theme-slight-margin) !important;
  font-size: 14px;
  border-radius: var(--theme-border-radius) !important;
}
