@use 'direction';

// DIRECTION
@include direction.biDirectional() using($start, $end) {
  .mat-select-panel {
    .mat-option-text {
      @if $start == left {
        direction: ltr;
      } @else {
        direction: rtl;
      }

      .mat-icon {
        margin-#{$end}: var(--theme-trailing-margin);
      }
    }
  }
}
